import React from "react";

const Action = () => {
    console.log('action')
    return ( 
        <div>
            Action
        </div>
    );
}

export default Action;